@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  @font-face {
    font-family: "Peyda";
    src: url("assets/fonts/eot/PeydaWeb-Thin.eot");
    src: url("assets/fonts/eot/PeydaWeb-Thin.eot?#iefix") format("embedded-opentype"),
        url("assets/fonts/woff2/PeydaWeb-Thin.woff2") format("woff2"),
        url("assets/fonts/woff/PeydaWeb-Thin.woff") format("woff"),
        url("assets/fonts/ttf/Peyda-Thin.ttf") format("truetype");
    font-weight: 200;
  }
  @font-face {
    font-family: "Peyda";
    src: url("assets/fonts/eot/PeydaWeb-Regular.eot");
    src: url("assets/fonts/eot/PeydaWeb-Regular.eot?#iefix") format("embedded-opentype"),
        url("assets/fonts/woff2/PeydaWeb-Regular.woff2") format("woff2"),
        url("assets/fonts/woff/PeydaWeb-Regular.woff") format("woff"),
        url("assets/fonts/ttf/Peyda-Regular.ttf") format("truetype");
    font-weight: 400;
  }

  body, html {
    @apply !bg-fixed !bg-no-repeat bg-[#893101] bg-cover min-h-screen font-['Peyda'] m-0 p-0  text-[#222222]  
  }
  .countdown-item{
    @apply flex flex-col items-center justify-center
  }
  .countdown-time{
    @apply text-2xl font-bold
  }
  .countdown-label{
    @apply text-lg
  }
  .circle{
    @apply inline-block align-middle w-1 h-1 mx-2 my-0 rounded-sm bg-current opacity-60
  }
}

